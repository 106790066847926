<template>
    <div>
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>微信设置</el-breadcrumb-item>
            <el-breadcrumb-item>界面设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form style="max-width: 600px;margin:0 auto" :model="form" ref="form" label-width="100px" class="form">
            <el-form-item align="center">
                <h3>微信端界面设置</h3>
            </el-form-item>
            <el-form-item label="首页顶部大图">
                <el-upload
                    class="avatar-uploader"
                    action="/api/upload/"
                    :show-file-list="false"
                    :on-success="handleSliderSuccess"
                    :before-upload="beforeUpload">
                    <img v-if="form.slider1" :src="form.slider1" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item align="center">
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    slider1: '',
                }
            }
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            handleSliderSuccess(res, file) {
                this.form.slider1 = res
            },
            beforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';

                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG或PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            async submitForm() {
                const resp = await this.$http.post(`/mingde/wechat/ui`, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                }
            },
            async getData() {
                const resp = await this.$http.get(`/mingde/wechat/ui`)
                if (resp.data.code == 200)
                    this.form = resp.data.data
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 100%;
        display: block;
    }
</style>
